<template>
  <div class='layout-sorter'>
    <template v-for='(rule, index) in rules'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][id]"' v-model='rule.id'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][configuration_type_id]"' v-model='rule.configuration_type_id'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][asset_layout_id]"' v-model='rule.asset_layout_id'>
      <input type='hidden' :name='"integrator[rules_attributes][" + index + "][_destroy]"' v-model='rule._destroy'>
    </template>
    <header>
      <slot></slot>
      <div class='sort-rules'>
        <section v-if='!rule._destroy' v-for='(rule, index) in rules'>
          <select v-model='rule.configuration_type_id'>
            <option v-for="rt in role_types" :value="rt.id">{{rt.name}}</option>
            }
          </select>
          <div style='text-align: center'>
            <i class="fas fa-arrow-right"></i>
          </div>
          <select v-model='rule.asset_layout_id'>
            <option v-for="at in asset_types" :value="at.id">{{at.name}}</option>
          </select>
          <a href='#' class='danger' @click.prevent.stop='removeRule(index)'><i class='icon fas fa-trash'></i>Remove</a>
        </section>
        <div style='margin-top: 1rem;display: inline-block'>
          <a href='#' @click.prevent.stop='addRule' class='button button--success'>Add Sort Rule</a>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  props: ["prerules", "asset_types", "role_types"],
  data () {
    return {
      rules: [],
      isShown: false
    }        
  },
  mounted () {
    this.rules = this.prerules.rules;
  },
  methods: {
    addRule () {
      this.rules.push({
        id: null,
        asset_layout_id: this.asset_types[0]["id"],
        configuration_type_id: this.role_types[0]["id"],
        _destroy: false
      })
    },
    removeRule (index) {
      if(confirm("Are you sure?")){
        this.rules[index]._destroy = true;
      }
    }
  }
}
</script>