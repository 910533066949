
const initMobileHeader = () => {
  window.addEventListener('load',function(){

    var headerButton = document.querySelector("#triggerHeader");
    var companySidebar = document.querySelector(".company-sidebar");

    if(headerButton) {
      headerButton.addEventListener("click", function(e) {
        console.log(companySidebar.style.display)
        e.preventDefault()
        if (companySidebar.style.display === "none" || companySidebar.style.display === "" ) {
          companySidebar.style.display = "block";
        } else {
          companySidebar.style.display = "none";
        }
      });

    }
  });
};

export { initMobileHeader };