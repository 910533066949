<template>
  <div style='position: relative;'>
    <a class='basic_info_trigger' href='#' v-if='isShown' @click.prevent.stop="isShown = !isShown"><i class="fas fa-eye-slash icon"></i>Hide Basic Info</a>
    <a class='basic_info_trigger' href='#' v-if='!isShown' @click.prevent.stop="isShown = !isShown"><i class="fas fa-eye icon"></i>Show Basic Info</a>
    <slot v-if='isShown'></slot>
  </div>
</template>

<script>
export default {
  props: [""],
  mounted () {
    
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

