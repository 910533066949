<template>
  <div>
    <apexchart width="380" type="bar" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  props: ["data"],
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    return {
      options: {
        xaxis: {
          title: {
          text: 'Rating',
        },
        colors: [
          '#008FFB',
          '#00E396',
          '#FEB019',
          '#FF4560',
          '#775DD0',
          '#546E7A',
          '#26a69a',
          '#D10CE8'
        ],
        labels: {
          rotate: -45
        },
          categories: ['1 star', '2 stars', '3 stars', '4 stars', '5 stars'
        ],
      },
      yaxis: {
        title: {
          text: '# of Companies',
        },
      },

        plotOptions: {
          bar: {
            
          }
        },
        dataLabels: {
          enabled: false
        }

      },
      series: [{
        name: 'Ratings',
        data: this.data
      }]
    }
  }
}
</script>

