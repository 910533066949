<template>
  <div>

    <flash-message></flash-message>
    <div style='position: relative;'>
      <a class='button button--secondary button--small' @click.prevent.stop="isShown = true"></i> <i class='fas fa-redo'></i>Rematch</a>
      <transition name='popopen'>
        <div class='duplicator duplicator__large' v-if='isShown'>
          <span><b>Match card to:</b></span>
          <div class='button-group'>
            <div class='search search--hitcher' v-click-outside="onClickOutside">
              <icon></icon>
              <input type='text'
                @click="showSearch = true"
                @input="onInput"
                @keydown.esc="showSearch = false"
                v-model='q' placeholder="Search...">
              <div class='search__box' v-show='showSearch'>
                <header class='search__header'>
                  <label><input @change.prevent.stop="onInput" v-model='include_archived' type='checkbox'>Include archived</label>
                </header>
                <ul v-if='results.length > 0'>
                  <li class='search__box__li__hitcher' v-if='result.id != asset_id' v-for='(result,i) in results' :key="i">
                    <h2>
                      <a target="_blank" :href='result.url' class='button button--primary button--small'>View</a>
                      <a href='#' @click.prevent="add(result.id)" class='button button--success button--small'>Match</a>
                      <a href='#' @click.prevent="add(result.id)">
                        {{result.name}}
                        <span class='search__span'>{{result.asset_type}} / {{result.company_name}}</span></a>
                      </a>
                    </h2> 
                  </li>
                </ul>
                <div class='empty' v-if='results.length == 0'>
                  <p >No results found</p>
                </div>
              </div>
            </div>
            <a href='#' class='button button--secondary' @click.prevent.stop="isShown = false">Cancel</a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import Icon from "../components/icon.vue";
import vClickOutside from 'v-click-outside'

export default {
  props: ["asset_id", "objectType", "url", "objectId", "company"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    add (id) {
      var that = this;
      that.$api.put(that.url, {
        company_id: id,
        matcher_id: that.objectId
      })
      .then(function (response) {
        that.newMatch = response.data.name;
        that.newMatchUrl = response.data.url;
        that.showSearch = false;

        that.flash("Rematched card!", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      }).catch(error => {
        alert(error.response.data)
      })
    },
    onInput () {

      var that = this;

      if (that.q.length > 0) {
        that.showSearch = true;
      }

      that.$api.get('/searches', {
        params: {
          q: that.q,
          type: "Asset",
          include_archived: that.include_archived,
          company: that.company
        }
      })
      .then(function (response) {
        that.results = response.data;
      })
    }
  },
  data: function () {
    return {
      q: "",
      isShown: false,
      results: [],
      showSearch: false,
      newMatch: "",
      newMatchUrl: "",
      include_archived: false,
    }
  },
  components: {
    Icon
  }
}
</script>