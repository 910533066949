<template>
  <div>
    <apexchart width="380" type="donut" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  props: ["data"],
  components: {
    apexchart: VueApexCharts,
  },
  data: function () {
    return {
      options: {
        plotOptions: {
          pie: {
            donut: {
              size: '65%'
            },
            expandOnClick: false
          }
        },
        labels: ['Incomplete', 'Complete']
      },
      series: this.data,
    }
  }
}
</script>

