<template>
  <article class='content content--article'>
    <div>
      <div id='contents'>
        <slot name='content'></slot>
      </div>
      <slot name='siblings'></slot>
    </div>
    <div class='hemingway__toc'>
      <h3>Table of Contents</h3>
      <a href='#' @click.prevent.stop='scrollTop' class='hemingway__toc__scroll'>Scroll to top</a>
      <div id='toc' ></div>
    </div>
  </article>
</template>

<script>

export default {
  props: ["url", "text"],
  data: function () {
    return {
      isShown: false
    }
  },
  mounted () {
    this.getContents();
  },
  methods: {
    scrollTop () {
      // This prevents the page from scrolling down to where it was previously.
      if ('scrollRestoration' in history) {
          history.scrollRestoration = 'manual';
      }
      // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded.Cross-browser supported.
      window.scrollTo(0,0);
    },
    toggleContent (e) {
      if (this.isShown == false) {
        this.getContents()
        this.isShown = true;
      } else {
        this.isShown = false;
      }
    },
    getContents () {

      var toc = "";
      var level = 0;

    document.getElementById("contents").innerHTML =
        document.getElementById("contents").innerHTML.replace(
            /<h([\d])>([^<]+)<\/h([\d])>/gi,
            function (str, openLevel, titleText, closeLevel) {
                if (openLevel != closeLevel) {
                    return str;
                }

                if (openLevel > level) {
                    toc += (new Array(openLevel - level + 1)).join("<ul>");
                } else if (openLevel < level) {
                    toc += (new Array(level - openLevel + 1)).join("</ul>");
                }

                level = parseInt(openLevel);

                var anchor = titleText.replace(/ /g, "_");
                toc += "<li><a href=\"#" + anchor + "\">" + titleText
                    + "</a></li>";

                return "<h" + openLevel + "><a name=\"" + anchor + "\">"
                    + titleText + "</a></h" + closeLevel + ">";
            }
        );

    if (level) {
        toc += (new Array(level + 1)).join("</ul>");
    }

    document.getElementById("toc").innerHTML += toc;
    }
  },
}
</script>

