<template>
  <div class='relater'>
    <div class='segment__header'>
      <h2>Related items</h2>
      <a href='#' v-if='!add && can' class='button button--success' @click.prevent.stop="add = true">New Related Item</a>
      <div v-if='add' class='search search--hitcher' v-click-outside="onClickOutside">
        <icon></icon>
        <input type='text'
          @click="showSearch = true"
          @input="onInput"
          @keydown.down="onArrowDown"
          @keydown.esc="showSearch = false"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter" v-model='q' placeholder="Search existing items...">
        <div class='search__box' v-show='showSearch'>
          <header class='search__header'>
            <label v-if='company'><input @change.prevent.stop="onInput" v-model='only_company' type='checkbox'>Search Only in Company</label>
            <label><input @change.prevent.stop="onInput" v-model='include_archived' type='checkbox'>Include archived</label>
          </header>

          <ul v-if='results.length > 0'>
            <li class='search__box__li__hitcher' v-for='(result,i) in results' :key="i">
              <h2>
                <a target="_blank" :href='result.url' class='button button--primary button--small'>View</a>
                <a href='#' @click.prevent="addItem(result.id, result.object_type)" class='button button--success button--small'>Add Relation</a>
                <a href='#' @click.prevent="addItem(result.id, result.object_type)">
                  {{result.name}}
                
                <span class='search__span' v-if='result.object_type == "Company" || result.object_type == "Article"'>
                  {{result.object_type}}
                </span>
                <span class='search__span' v-if='result.object_type == "Password"'>{{result.asset_name}} / {{result.company_name}}</span>
                <span class='search__span' v-if='!["Company", "Article", "Password"].includes(result.object_type)'>{{result.asset_type}} / {{result.company_name}}</span>
                </a>
              </h2> 
            </li>
          </ul>
          <div class='empty' v-if='results.length == 0'>
            <p >No results found</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <ul class='segment--tab__list'>
        <li v-for='(relation, index) in relations'>
          <div>
            <h3>
              <a :href='relation.url'>
                <i v-if='relation.is_inverse' class="icon fas fa-exchange-alt"></i>{{relation.name}}
              </a>
            </h3>
            <p v-show='relation.description && relation.description.length > 0'>{{relation.description}}</p>
            <div v-if='showUpdate == index'>
              <form @submit.prevent.stop="updateRelation(relation.id, relation.description, index)">
                <input type='text' class='add_description' v-model='relation.description' placeholder="add description...">
                <input class='button button--primary button--small' value='Update' type='submit'>
              </form>
            </div>
          </div>

          <nav>

            <a v-if='can' href='#' @click.prevent="editRelation(index)" class='button button--secondary button--small'>Edit Description</a>
            <a v-if='can' href='#' @click.prevent="removeRelation(relation.id, index)" class='button button--danger button--small'>Delete</a>
          </nav>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import vClickOutside from 'v-click-outside'
import Icon from "../components/icon.vue";

export default {
  name: 'relater',
  props: ["id", "url", "can", "company", "asset_layouts", "fromable_type"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted () {

    var that = this;

    fetch(that.url)
    .then((resp) => resp.json())
    .then(function(data) {
      that.relations = data.relations
    })
  },
  methods: {
    updateRelation(id, description, index) {
      var that = this;
      that.$api.put(`/relations/${id}`, { 
        relation: {
          description: description
        }
      }).then(function (response) {
        that.showUpdate = -1;
      }).catch(error => {
        console.log(error)
      })
    },
    editRelation (index) {
      this.showUpdate = index;
    },
    removeRelation (id, index) {
      var that = this;
      if(confirm('Are you sure?')) {
        that.$api.delete(`/relations/${id}`).then(function (response) {
          that.relations.splice(index, 1);
        }).catch(error => {
          console.log(error)
        })
      }
    },
    addItem (id, type) {
      var that = this;

      that.$api.post('/relations', {
        relation: {
          fromable_type: that.fromable_type || "Asset",
          fromable_id: that.id,
          toable_type: type,
          toable_id: id
        }
      })
      .then(function (response) {
        that.relations.unshift(response.data)
        that.showSearch = false;
      }).catch(error => {
        alert(error.response.data)
      })
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      if (this.arrowCounter != -1)
        this.$refs["a" + this.arrowCounter][0].click();
    },
    onClickOutside(evt) {
      this.showSearch = false;
      this.arrowCounter = -1;
    },
    onInput () {

      var that = this;

      if (that.q.length > 0)
        that.showSearch = true

      axios.get('/searches', {
        params: {
          q: that.q,
          company: that.only_company ? that.company : "",
          include_archived: that.include_archived,
        }
      })
      .then(function (response) {
        console.log(response.data)
        that.results = response.data;
      })
    }
  },
  data: function () {
    return {
      relations: [],
      q: this.old_query || "",
      results: [],
      showUpdate: -1,
      showFilters: false,
      arrowCounter: -1,
      showSearch: false,
      add: false,
      include_archived: false,
      only_company: this.company ? true : false 
    }
  },
  components: {
    Icon
  }
}
</script>