<template>
  <div class="menubar__subtoolbar menubar__subtoolbar--image" v-if="show">
    <div>
      <vue-dropzone
        ref="myVueDropzone"
        id="dropzone"
        @vdropzone-success="vfileUploaded"
        :options="dropzoneOptions"
      >
      </vue-dropzone>
    </div>

    <footer>
      <div class='button-group'>
        <a href='#' class='button button--primary' @click.prevent.stop="insertImage">Insert Image</a>
        <a href='#' class='button button--secondary' @click.prevent.stop="show = false;">Close</a>
      </div>
    </footer>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "axios";

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      imageSrc: "",
      command: null,
      show: false,
      tab: 1,
      dropzoneOptions: {
          url: '/upload',
          thumbnailWidth: 150,
          maxFilesize: 100,
          headers: { "My-Awesome-Header": "header value" }
      }
    };
  },
  computed: {
    validImage() {
      return (
        this.imageSrc.match(/unsplash/) !== null ||
        this.imageSrc.match(/\.(jpeg|jpg|gif|png)$/) != null
      );
    }
  },
  methods: {
    showModal(command) {
      // Add the sent command
      this.command = command;
      this.show = true;
    },
    vfileUploaded(file) {
      this.imageSrc = file.dataURL;
    },
    // fileChange(e) {
    //   const file = this.$refs.file.files[0];
    //   const uploadUrl = `https://httpbin.org/post`;
    //   let formData = new FormData();

    //   formData.append("file", this.file);

    //   console.log("Uploading...");

    //   axios.post(uploadUrl).then(data => {
    //     // Take the URL/Base64 from `data` returned from server
    //     alert("Your image has been uploaded to the server");
    //     alert("NOTE THIS IS A DUMMY DEMO, THERE IS NO BACKEND");

    //     this.imageSrc = "https://source.unsplash.com/random/400x100";
    //   });
    // },
    insertImage() {
      const data = {
        command: this.command,
        data: {
          src: this.imageSrc
          // alt: "YOU CAN ADD ALT",
          // title: "YOU CAN ADD TITLE"
        }
      };

      this.$emit("onConfirm", data);
      this.closeModal();
    },

    closeModal() {
      this.show = false;
      this.imageSrc = "";
      this.tab = 1;
    }
  }
};
</script>

<style scoped>

</style>
