<template>
  <div>
    <a href='#' @click.prevent.stop="toggle" class='button portal__header-button button--secondary'>{{name}}</a>
  </div>
</template>

<script>
export default {
  props: ["name"],
  mounted () {
  },
  data: function () {
    return {
      isShown: false
    }
  },
  methods: {
    toggle () {
      var sidebar = document.querySelector(".portal__sidebar")
      if (this.isShown) {
        this.isShown = false;
        sidebar.classList.remove("portal__sidebar--show")
      } else {
        this.isShown = true
        sidebar.classList.add("portal__sidebar--show")
      }
    }
  }
}
</script>