<template>
  <div style='position: relative;'>
    <a href='#' v-if='!edit' class='button button--success' @click.prevent.stop="isShown = true"><i class='fas fa-plus'></i>New {{name}}</a>
    <a href='#' v-if='edit' class='button button--secondary' @click.prevent.stop="isShown = true">Edit Folder</a>
    <div class='duplicator' v-if='isShown'>
      <span v-if='!edit'><b>Create {{name}}</b></span>
       <span v-if='edit'><b>Edit Folder</b></span>
      <div class='button-group'>
        <slot></slot>
        <a href='#' class='button button--secondary' @click.prevent.stop="isShown = false">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "edit"],
  mounted () {
    
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

