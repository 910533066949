<template>
  <div class='quick_notes'>
    <header>
      <h2>Quick Notes</h2>
      <div class='button-group' v-if='can'>
        <a href='#' v-if='!isShown' class='button button--secondary' @click.prevent.stop="isShown = !isShown"><i class='fas fa-edit'></i> Edit</a>
        <a href='#' v-if='isShown' class='button button--secondary' @click.prevent.stop="isShown = !isShown">Cancel Editing</a>
        <a href='#' v-if='isShown' class='button button--primary' @click.prevent.stop="save">Update</a>
      </div>
    </header>
    <div v-html='notes'></div>
    <div class='quick_notes__writer' v-if='isShown'>
      <writer v-model="notes" :precontent="notes" />
    </div>
  </div>
</template>

<script>
import Writer from './writer.vue';

export default {
  props: ["company_url", "prenotes", "can"],
  components: {
    Writer
  },
  methods: {
    save () {
      var that = this;

      that.$api.put(that.company_url, {
        company: {
          notes: that.notes
        }
      })
      .then(function (response) {
        that.notes = response.data.notes;
        that.isShown = false
      })
    }
  },
  data () {
    return {
      notes: this.prenotes || "",
      isShown: false
    }
  }
}
</script>

