<template>
  <div class='drop popopen' @keydown.esc="toggle(0)" v-click-outside="onClickOutside">
    <a href='#' @click.prevent.stop='switchToggle' class='profile'>
      <b>{{name}}</b>
      <img :src='image' width='32' height='32'>
    </a>
    <transition name='popopen'>
      <slot v-if='isShown' ></slot>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { mapGetters } from 'vuex'

export default {
  props: ["name", "image"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("globals", {
      'isShown': 'showDropper'
    })
  },
  methods: {
    onClickOutside (event) {
      this.toggle(0)
    },
    toggle(newSub) {
      this.$store.commit('globals/changeSub', newSub)
    },
    switchToggle () {
      if (this.isShown) {
        this.toggle(0)
      } else {
        this.toggle(3)
      }
    }
  },

}
</script>

