<template>
  <div class='roler'>
    <input type='hidden' :value='security_level' name='user[security_level]'>
    <input type='hidden' :value='group_id' name='user[group_id]'>

    <div v-if='uneditable'>
      <div class='form-group'>
        <p class='roler__uneditable'>This user's role can not be edited as it is the first super admin.</p>
      </div>
    </div>

    <div v-if='!uneditable'>
      <div class='form-group'>
        <label class='required'>Role</label>
        <div class='roler__choices'>
        <label>
          <input value='portal_member' v-model='security_level' type='radio'>
          <h2>Portal Member</h2>
          <p>Can view documentation in one company</p>
        </label>
        <label>
          <div class="ribbon ribbon-top-right"><span>Billed</span></div>
          <input value='spectator' v-model='security_level' type='radio'>
          <h2>Spectator</h2>
          <p>Can view documentation in all companies</p>
        </label>
        <label>
          <div class="ribbon ribbon-top-right"><span>Billed</span></div>
          <input value='author' v-model='security_level' type='radio'>
          <h2>Author</h2>
          <p>Can view/create/update documentation in all companies</p>
        </label>
        <label>
          <div class="ribbon ribbon-top-right"><span>Billed</span></div>
          <input value='editor' v-model='security_level' type='radio'>
          <h2>Editor</h2>
          <p>Can create/update/flag/share documentation in all companies</p>
        </label>
        <label>
          <div class="ribbon ribbon-top-right"><span>Billed</span></div>
          <input value='admin' v-model='security_level' type='radio'>
          <h2>Admin</h2>
          <p>Can perform most administration tasks for all companies</p>
        </label>
        <label v-if='add_super_admin'>
          <div class="ribbon ribbon-top-right"><span>Billed</span></div>
          <input value='super_admin' v-model='security_level' type='radio'>
          <h2>Super Admin</h2>
          <p>Can perform all administration tasks for all companies</p>
        </label>
        </div>
      </div>

      <company-grabber v-if='security_level == "portal_member"' :existing='c' name='user[company_id]'></company-grabber>

      <div class='form-group' v-if='(groups.length > 0)' v-show='security_level != "portal_member" && security_level != "admin" &&security_level != "super_admin"'>
        <label>Group</label>
        <select v-model='group_id'>
          <option></option>
          <option :value='group.id' v-for='group in groups'>{{group.name}}</option>
        </select>
      </div>

    </div>
  </div>
</template>

<script>
import CompanyGrabber from './company_grabber.vue';
const axios = require('axios');
export default {
  props: ["uneditable", "add_super_admin", "presecurity_level", "pregroup_id", "groups", "precompany_id","precompany_name"],
  methods: {
  },
  mounted () {

  },
  components: {
    CompanyGrabber
  },
  data: function () {
    return {
      c: {id: this.precompany_id, name: this.precompany_name},
      security_level: this.presecurity_level || "spectator",
      group_id: this.pregroup_id
    }
  }
}
</script>